
interface DetailRelatoryPlaca {
  id: number | null;
  idLoja: number | null;
  idUsuario: number | null;
  placa: string | null;
  retornoBusca: string | null; 
  created_at: string | null;
  updated_at: string | null;
  nomeFantasia: string | null;
  codGrupo: number | null;
  nomeGrupo: string | null;
  nomeUsuario: string | null;
}

import { computed, defineComponent, onBeforeMount, Ref, ref, watch } from "vue"
import Title from "@/components/Title.vue";
import Button from "@/components/Button.vue";
import Api from "@/services/Api";
import { useRoute, useRouter } from "vue-router";
import useAlert from "../../composables/Alert";
import { Modal } from "bootstrap";
import useEmitter from "@/composables/Emmiter";
import { RelatoryFilter } from "@/layout/header/partials/filters/FilterRelatory.vue";
import ModalDetalheBuscaPlaca from "./components/ModalDetalheBuscaPlaca.vue";

export default defineComponent({
  components: { Title, Button, ModalDetalheBuscaPlaca},
  name: "RelatorioBuscaPlaca",
  setup() {

    const { showTimeAlert } = useAlert();
    const emitter = useEmitter();
    const auxModal: Ref<any> = ref('');
    const router = useRouter();
    const route = useRoute();
    const idLoja: Ref<any> = ref(null);
    const listaBuscaPlaca: Ref<DetailRelatoryPlaca[]> = ref([])
    const paginasNumeradas: Ref<any> = ref([]);
    const filteredProducts: Ref<any> = ref([]);
    const inputFind: Ref<string> = ref('');
    const produto: Ref<any> = ref({})
    const paramsRelatorio: Ref<RelatoryFilter | null> = ref(null)
    const openFind: Ref<boolean> = ref(false)
    const findActive: Ref<boolean> = ref(false)
    const totalBuscas: Ref<number> = ref(0)
    const retornoBusca: Ref<any> = ref(null)

    function catchIdParams() {
      idLoja.value = route.query.storeId;
    }

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal.value = new Modal(auxElement);
      auxModal.value.show();
    };

    function closeModal() {
      auxModal.value.hide()
    }

    // function paginar(str: string) {
    //   if (str === '+') return paramsRelatorio.value.pagina++
    //   paramsRelatorio.value.pagina--
    // }

    async function getRelatorioBuscaPlaca() {
      try {
        const { data } = await Api.get("relatorio/getRelatorioBuscaPlaca", { ...paramsRelatorio.value })
        listaBuscaPlaca.value = data
        totalBuscas.value = data.length
      } catch (error) {
        console.log(error);
      }
    }

    async function downloadRelatorioBuscaPlaca(){
      const url = `${process.env.VUE_APP_URL_API}relatorio/downloadRelatorioBuscaPlaca?grupo=${paramsRelatorio.value?.grupo}&loja=${paramsRelatorio.value?.loja}&eventos=${paramsRelatorio.value?.eventos}&usuarios=${paramsRelatorio.value?.usuarios}&data=${paramsRelatorio.value?.data}&url=${paramsRelatorio.value?.url}&metodo=${paramsRelatorio.value?.metodo}&placa=${paramsRelatorio.value?.placa}&headers=${paramsRelatorio.value?.headers}&envio=${paramsRelatorio.value?.envio}&retorno=${paramsRelatorio.value?.retorno}`;
      window.open(url, "_blank");
    }

    emitter.on("filtro-relatorios", (valor: any) => {
      paramsRelatorio.value = valor.filtroRelatorios
      getRelatorioBuscaPlaca()
    })

    function usePagination() {
      const state = ref({
        currentPage: 1,
        itemsPerPage: 20,
        itemsOnPage: 0,
      });

      const pageCount = computed(() => Math.ceil(totalBuscas.value / state.value.itemsPerPage));

      const itemsOnPage = computed(() => {
        const startIndex = (state.value.currentPage - 1) * state.value.itemsPerPage;
        const endIndex = startIndex + state.value.itemsPerPage;
        return listaBuscaPlaca.value.slice(startIndex, endIndex);
      });

      const setCurrentPage = page => {
        state.value.currentPage = page;
        state.value.itemsOnPage = itemsOnPage.value.length;
      };

      return {
        state,
        setCurrentPage,
        pageCount,
        itemsOnPage,
      };
    }

    const { state, setCurrentPage, pageCount, itemsOnPage } = usePagination();

    const numberPages = () => {
      for (let i = 1; i <= pageCount.value; i++) {
        paginasNumeradas.value = i;
      }
    };

    watch(
      () => pageCount.value,
      () => numberPages()
    )

    // watch(
    //   () => state.value.currentPage,
    //   () => { paramsRelatorio.value.pagina = state.value.currentPage, getRelatorioBuscaPlaca() }

    // )

    onBeforeMount(() => catchIdParams());

    return {
      router,
      listaBuscaPlaca,
      state,
      setCurrentPage,
      pageCount,
      itemsOnPage,
      numberPages,
      paginasNumeradas,
      inputFind,
      filteredProducts,
      openModal,
      produto,
      getRelatorioBuscaPlaca,
      auxModal,
      closeModal,
      idLoja,
      paramsRelatorio,
      /*paginar,*/
      openFind,
      totalBuscas,
      findActive,
      retornoBusca,
      downloadRelatorioBuscaPlaca
    };
  },
})
