
import Title from "@/components/Title.vue"
import { defineComponent } from "vue"

export default defineComponent({
  name: "ModalDetalheBuscaPlaca",
  components: {Title },
  props: { buscaDetalhada: Object, auxModal: Object },

  setup(props) {

    return {  }
  }

})
